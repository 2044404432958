<template>
	<CustomerForm @submit="createCustomer" @cancel="cancel" />
</template>

<script>
import { CREATE_CUSTOMER } from "../store/action-types"
import { createNamespacedHelpers, mapState } from "vuex"
import { RESET_CUSTOMER_FILTERS } from "../store/mutation-types"
import { GET_TYPES_ORDER } from "../../typeOrder/store/action-types"
import { PARAMS } from "../../../shared/plugins/constants"
import { RESET_STATE } from "../../typeOrder/store/mutation-types"
const { mapActions, mapMutations } = createNamespacedHelpers("customers")
const {
	mapActions: typeOrderActions,
	mapState: typeOrderState,
	mapMutations: typeOrderMutations,
} = createNamespacedHelpers("typeOrder")
export default {
	name: "CreateCustomer",
	components: {
		CustomerForm: () => import("../components/CustomerForm"),
	},
	data() {
		return {
			offset: PARAMS.OFFSET,
		}
	},
	computed: {
		...typeOrderState(["recentFilters"]),
		...mapState(["pageChanged"]),
	},
	watch: {
		pageChanged(val) {
			if (val) {
				this.offset = val.offset
				this.getTypes()
			}
		},
	},
	beforeDestroy() {
		this.RESET_CUSTOMER_FILTERS()
		this.RESET_STATE()
		this.$store.commit("set", ["pageChanged", null])
	},
	created() {
		this.getTypes()
	},
	methods: {
		...mapActions({ CREATE_CUSTOMER }),
		...mapMutations({ RESET_CUSTOMER_FILTERS }),
		...typeOrderActions({ GET_TYPES_ORDER }),
		...typeOrderMutations({ RESET_STATE }),
		async createCustomer(params) {
			await this.CREATE_CUSTOMER(params)
		},
		cancel() {
			this.$router.push({ name: "CustomerManagement" })
		},
		async getTypes() {
			const params = {
				data: {
					...this.recentFilters,
					offset: this.offset,
				},
				notLoading: false,
			}
			await this.GET_TYPES_ORDER(params)
		},
	},
}
</script>

<style></style>
